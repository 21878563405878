import React from "react";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { Modal } from "@fluentui/react";


export class MakeFoto extends React.Component<{
    hidden:boolean,
    onTakePhoto:(dataURI:string) => void
}> {
    render(): React.ReactNode {
        return  <Modal isOpen={!this.props.hidden}>
        
        <Camera
            onTakePhoto={(dataURI:string) =>  this.props.onTakePhoto(dataURI)}
            
        /></Modal>
    }
}