
import '../config';
export const API = {
    token:{
        main:{
            token:'',
            expires:0,
            "human-expires":''
        },
        refresh:{
            token:'',
            expires:0,
        }
    },
    base:"https://tools.huebschi.de/api",
    getToken(){
        let APIS = JSON.parse(localStorage.API);
        var url = this.base+'/token';
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, false);  // synchronous request
        xhr.setRequestHeader("Authorization",'Bearer ' + APIS.refresh.token);
        xhr.send(null);
        this.token= JSON.parse(xhr.responseText); 
        console.debug("GOT an new Token valid till: "+this.token?.main['human-expires'])
        //APIS.token = this.token;
        localStorage.setItem('API', JSON.stringify(this.token));
    },
    getAuth(){
        var timeNow=(Math.round((new Date()).getTime()/1000));
        let diff = this.token.main.expires - timeNow
        if (diff < 5) {
           this.getToken();
        }
        return 'Bearer ' + this.token.main.token;
    },

    get(url:string) { 
        var token =  this.getAuth();
        url = this.base+url;
        return fetch(url, {
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }, 
            method: 'GET',
            mode: 'cors'
        }).then(response => response.json());
    },
    download(url:string,data:any) { 
        var token =  this.getAuth();
        url = this.base+url;
        return fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }, 
            method: 'POST',
            mode: 'cors'
        }).then(response => response.blob());
    },

    post(url:string, data:any) {
        var token =  this.getAuth();
        url = this.base+url;
        return fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            method: 'POST',
            mode: 'cors'
        }).then(response => response.json())
    },

    patch(url:string, data:any) {
        var token =  this.getAuth();
        url = this.base+url;
        return fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            method: 'PATCH',
            mode: 'cors'
        }).then(response => response.json())
    },

    put(url:string, data:any) {
       
        var token =  this.getAuth();
        url = this.base+url; 
        return fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            method: 'PUT',
            mode: 'cors'
        }).then(response => response.json())
    },

    delete(url:string, data?:any) {
       
        var token =  this.getAuth();
        url = this.base+url; 
        return fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            method: 'DELETE',
            mode: 'cors'
        }).then(response => response.json())
    }
}
