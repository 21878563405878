import React from "react";
import { DatePicker, Dropdown, SelectionMode, IGroup, Toggle, Position, PrimaryButton, IDropdownOption, IDropdownProps, mergeStyleSets, getTheme, Icon, Pivot, PivotItem } from "@fluentui/react";
import {ListView,SpinEdit, Libery, API } from "../../common";
import config from "../../config";
import { DarlehenInfo } from "./konten";
import { onCLS } from "web-vitals";


export interface  KreditrechnerProps {
  konten:any[],
  Zinsausgabe?:{
    konto:number,
    von:Date,
    bis:Date,

  }
}
interface prevItem {
  Datum:string,
  Betrag?:number,
  Rate?:number,
  Bestand:number,
  Haushaltskonto:number,
  Text:string,
  Datum2:string,
  Datum3:Date,
  zinssatz?:number,
  Zins?:number,
  Zinssumme?:number
}
interface KreditrechnerState {
  auszahlung:Date,
  ab:Date,
  von:Date,
  bis:Date,
  loading:boolean,
  summe:number,
  zins:number,
  rate:number,
  laufzeit:number,
  ratenJahr:number,
  konto:number,
  prevItems : any[],
  src?:any

}
export class Kreditrechner extends React.Component<KreditrechnerProps, KreditrechnerState > {
  
  state:KreditrechnerState = {
    summe:0,
    zins: 0.05,
    rate:300,
    laufzeit:1,
    ratenJahr:12,
    konto:0,
    loading:false,
    auszahlung:new Date,
    ab:new Date,
    von:new Date,
    bis:new Date,
    prevItems:[]
  }
  constructor(props:KreditrechnerProps){
    super(props);

    if (typeof(props.Zinsausgabe) !=='undefined') {
      this.setState(props.Zinsausgabe)
    }
  }
  preparePrevItems(data:any):Promise<any>{
    return API.post('/fibu/pdf?format=json&',data).then((doc:any)=>{
      let Items:prevItem[] = doc.lines.map((line:prevItem, index:number)=>{
        line.zinssatz = 0;
        let Konto = this.props.konten.filter((Konto:any)=>Konto.ID === this.state.konto || this.props.konten.length===1);
        let C:any=(Konto.map((konto:any)=>konto?.meta.DL))
        if (C?.length>0) {
          C=C[0];
        }
        C?.forEach((dl:DarlehenInfo) => {
          let Beginn = (new Date(String(dl.ab))).valueOf();
          let Ende = (new Date(String(dl.Ende))).valueOf();
          let Akt = (new Date(line.Datum2)).valueOf();
          if (Beginn <= Akt && ( Akt<=Ende || !(Ende>0) ) ){
            line.zinssatz = dl.Zins
          }
        })

        return {
            
          key:index,
          'Nr':index+1,
          Rate:line?.Betrag,
          zinssatz:line.zinssatz,
          Tilgung: line.Betrag,
          Bestand:line.Bestand,
          Zinssumme:0,
          Datum:line.Datum,
          Datum2:line.Datum2,
          Haushaltskonto:line.Haushaltskonto
        };
      })
      let summe = 0;
      for (let j=0, c=Items.length; j<c; j++) {
        if (j > 0 ) {
          let tage = Math.ceil(((new Date(Items[j].Datum2)).valueOf()-(new Date(Items[j-1].Datum2)).valueOf())/(24*60*60*1000))
          Items[j].Zins = Math.round(Items[j].Bestand*(Items[j].zinssatz||0)/365*tage)/100;
          summe+=Items[j].Zins||0;
          if(Items[j].Haushaltskonto===24) {
            summe -= Items[j].Rate || 0
          }

          Items[j].Zinssumme = Math.round(summe*100)/100;
        } else {
          Items[j].Zins = 0;
        }
      }
      let tage = Math.abs(Math.ceil(((new Date).valueOf()-(new Date(Items[Items.length-1].Datum2)).valueOf())/(24*60*60*1000)))-1;
      let Konto = this.props.konten.filter((Konto:any)=>Konto.ID === this.state.konto || this.props.konten.length===1);
      let C:any=(Konto.map((konto:any)=>konto?.meta.DL))
      if (C?.length>0) {
        C=C[0];
      }
      let Zins:number | undefined = 0;
      C?.forEach((dl:DarlehenInfo) => {
        let Beginn = (new Date(String(dl.ab))).valueOf();
        let Ende = (new Date(String(dl.Ende))).valueOf();
        let Akt = (new Date()).valueOf();
        if (Beginn <= Akt && ( Akt<=Ende || !(Ende>0) ) ){
          Zins = dl.Zins
        }
      })
      if (Items.length > 0){
        Items[Items.length-1].Zinssumme=(Items[Items.length-1].Zinssumme || 0)+Items[Items.length-1].Bestand/100*Zins/365*tage;
      }
      return Items
    })
  }
  componentDidUpdate(prevProps: Readonly<KreditrechnerProps>, prevState: Readonly<KreditrechnerState>, snapshot?: any): void {
    if (
         prevState.konto !== this.state.konto      
      || prevState.von !== this.state.von
      || prevState.bis !== this.state.bis
      || prevState.summe !== this.state.summe
      || prevState.zins !== this.state.zins
      || prevState.rate !== this.state.rate
      || prevState.auszahlung !== this.state.auszahlung
      || prevState.laufzeit !== this.state.laufzeit
      || prevState.ratenJahr !== this.state.ratenJahr
      || prevState.ab !== this.state.ab
    ) {
      let data = {
        type:'Kontostandsentwicklung',
        konto: this.state.konto,
        range:{
          type:'range',
          von: this.state.von?.toISOString(),
          bis: this.state.bis?.toISOString()
        }
      }
      this.preparePrevItems(data).then((Items:any) => this.setState({prevItems:Items},()=>{
        API.download('/fibu/pdf',{
          "type":"Kredit",
          data:this.getItems()
        }).then((doc:any)=>{
          const  reader = new FileReader();
          reader.addEventListener("load", () => {
            this.setState({loading:false, src:reader.result||''}/*,()=>setTimeout(()=>this.getDoc(),30000)*/)
          }, false);
          reader.readAsDataURL(doc);
        })
      }))
    }
    

  }
  getItems(){
    let old = (this.state.prevItems.length > 0 ? this.state.prevItems[this.state.prevItems.length-1].Bestand: 0)
    let d=new Date(this.state.ab);
    let Rate = this.Anulitaet(this.state.summe+old, this.state.laufzeit, this.state.zins)
    let Rest = this.state.summe+old;
    let Zinssumme = this.state.prevItems.reduce((sum, item)=>sum +item.Zins,0);
    return this.state.prevItems.concat(Array.from(Array(this.state.laufzeit).keys()).map((item:any, index:number)=>{
      let Zinspontenz:number=Math.pow(1+(this.state.zins)/this.state.ratenJahr,this.state.laufzeit);
      let S3:number=Math.pow(1+(this.state.zins)/this.state.ratenJahr,index);
      let Zins =  (this.state.summe+old) * (Zinspontenz-S3) / (Zinspontenz-1) * (this.state.zins)/this.state.ratenJahr;
      Rest-=Rate-Zins;
      Zinssumme+=Zins;
      d.setMonth(d.getMonth()+12/this.state.ratenJahr);
      return {
        key:index,
        'Nr':index+1,
        Datum:config.DatePicker.formatDate(d),
        Rate:Rate,
        Zins:Zins,
        zinssatz:this.state.zins*100,
        Tilgung: Rate-Zins,
        Bestand:Rest,
        Zinssumme:Zinssumme
      }
     }));
  }
    render(){
      let KontoO:IDropdownOption[] = this.props.konten.map((konto:any):IDropdownOption=>{
        let I:IDropdownOption= {key:konto.ID, text:konto.Bezeichnung}
        return I;
      });
      let Groups:IGroup[]=[]
      if (this.state.prevItems.length > 0 ){
        Groups.push({
          key:'before',
          name:'bisher gelaufen',
          startIndex:0,
          count: this.state.prevItems.length,
          isCollapsed:!true
        })
      }
      Groups.push( {
        key:'future',
        name:'zukünftig',
        startIndex:this.state.prevItems.length,
        count: this.state.laufzeit
      })

       let Items:any[]=this.getItems();

        return <>
        <div  className="flex-wrap-m kreditrechner">
          
        <DatePicker {...config.DatePicker} 
            onSelectDate={(e: Date | null | undefined) => {
              if (e!==null && e!==undefined) {
                let newState:any =  {auszahlung:e};
                if (this.state.ab < e || this.state.auszahlung===this.state.ab ) {
                  newState.ab = e
                }
                this.setState(newState)
              }
            }}
            label="Auszahlung"
            disabled={this.state.loading }
            value={this.state.auszahlung}
            formatDate={(date?:Date|undefined) => {
                return date?.toLocaleDateString('de-DE',{ year: '2-digit', month: '2-digit', day: '2-digit' }) || ''
            }}
            className="Datum"
          />
          
        <div className="zahl">
        <SpinEdit
          label="Summe"
          labelPosition={0}
          value={this.state.summe+''}
          min={0}
          step={100}
          onChangeCustom={(value:number)=>this.setState({summe: value})}
        />
        </div>
        <div className="zahl">
        <SpinEdit
          label="Jahreszins"
          labelPosition={0}
          value={(this.state.zins*100)+''}
          min={0}
          step={0.01}
          onChangeCustom={(value:number)=>this.setState({zins: value/100})}
        />
        </div>
        <DatePicker {...config.DatePicker} 
            onSelectDate={(e: Date | null | undefined) => {
              if (e!==null && e!==undefined) {
                this.setState({ab:e})
              }
            }}
            label="ab"
            disabled={this.state.loading }
            value={this.state.ab}
            className="Datum"
            formatDate={(date?:Date|undefined) => {
                return date?.toLocaleDateString('de-DE',{ year: '2-digit', month: '2-digit', day: '2-digit' }) || ''
            }}
          />
        <div className="zahl">
        <SpinEdit
          label="Laufzeit"
          labelPosition={0}
          value={this.state.laufzeit+''}
          min={0}
          max={600}
          step={1}
          onChangeCustom={(value:number)=>this.setState({laufzeit: value})}
        /></div>
        <Dropdown 
          label="Typ"
          options={[
              {key:12, text:'monatlich'},
              {key:4, text:'quartalsweise'},
              {key:2, text:'halbjährlich'},
              {key:1, text:'jährlich'}
            ]}
          selectedKey={this.state.ratenJahr}
          onChange={(e:React.FormEvent<HTMLDivElement>, option?:IDropdownOption)=>this.setState({ratenJahr:parseFloat(option?.key+'')||1})}
          className="zahl"
        />
        <div style={{flexGrow:5, minWidth:'250px'}}>
        <Dropdown 
          label="Konto einbeziehen"
          options={[{key:0, text:'keins'},...KontoO]}
          selectedKey={this.state.konto}
          onChange={(e:React.FormEvent<HTMLDivElement>, option?:IDropdownOption)=>this.setState({konto:parseFloat(option?.key+'')||0, von:(new Date(2003,0,1))})}
        />
        </div>
        <div style={{display:'flex'}}>
          <DatePicker {...config.DatePicker} 
            onSelectDate={(e: Date | null | undefined) => {
              if (e!==null && e!==undefined) {
                this.setState({von:e})
              }
            }}
            label="von"
            disabled={this.state.loading || this.state.konto===0}
            value={this.state.von}
            className="Datum"
            styles={{root:{ marginRight: '0.5em'}}}
            formatDate={(date?:Date|undefined) => {
                return date?.toLocaleDateString('de-DE',{ year: '2-digit', month: '2-digit', day: '2-digit' }) || ''
            }}
          />
          <DatePicker {...config.DatePicker} 
            onSelectDate={(e: Date | null | undefined) => {
              if (e!==null && e!==undefined) {
                this.setState({bis:e})
              }
            }}
            label="bis"
            disabled={this.state.loading || this.state.konto===0}
            value={this.state.bis}
            className="Datum"
            formatDate={(date?:Date|undefined) => {
                return date?.toLocaleDateString('de-DE',{ year: '2-digit', month: '2-digit', day: '2-digit' }) || ''
            }}
          />
        </div>
        </div>
        <Pivot className="flex-grow flex-column">
          <PivotItem headerText="ScrollList" key="scroll">
            <ListView
              items={Items}
              layoutMode={1}
              groups={Groups}
              columns={[
                {
                  name:"Nr",
                  minWidth:20,
                  maxWidth:30
                },
                {
                  name:"Datum",
                  minWidth:50,
                  maxWidth:70
                },
                {
                  name:"zinssatz",
                  minWidth:50,
                  maxWidth:70
                }, /**
                {
                  fieldName:"Haushaltskonto",
                  name:"Haushaltskonto",
                  minWidth:50,
                  maxWidth:70
                }, */
                ...['Rate','Tilgung','Zins','Bestand', 'Zinssumme'].map((cl:any)=>{
                  return {
                  name:cl,
                  minWidth:50,
                  maxWidth:80,
                  onRender:(item:any)=>{
                      return (<span key={"preis"+item.id} style={{display:'block',textAlign:'right',maxWidth:"104px", fontWeight:item.ID==0?'bold':''}}>
                        {Libery.formatCurrency(item[cl])
                            }</span>)
                          }
                        }
                })
              ].map((I:any,index:number)=>{
                if (typeof(I.fieldName)==='undefined') {
                  I.fieldName = I.name
                }
                I.key = index;
                return I;
              })}
              selectionMode={SelectionMode.none}
            />

          </PivotItem>
          <PivotItem headerText="Print" key="Print">
          <iframe src={this.state.src}  style={{width:'100%',height:'100%', border:0}}/>
          </PivotItem>
        </Pivot>
        </>
    }
    zinssatzBerechnen(Summe:number, Monate:number, Rate:number):number {  
      if (Monate*Rate > Summe) {
      
        let min, max,avg, minA, maxA, avgA:number;
        let count = 0;  
        min = 0.0000000001, max=1;
        do {
          minA = this.Anulitaet(Summe, Monate, min);
          maxA = this.Anulitaet(Summe, Monate, max);
          avgA = this.Anulitaet(Summe, Monate, (min+max)/2);
          avg=(min+max)/2
          if (Rate > minA && Rate < avgA) {
            max -=(max-min) / 2 ;
          } 
          if (Rate > avgA && Rate < maxA) {
            min += (max-min) / 2 ;
          } 
          count++;
        } while (Math.abs(avgA-Rate) > 0.00001)
        

        return avg;
      } else {
        return 0;
      }
    }
    Anulitaet(Betrag:number, Laufzeit:number,Zins:number):number {
      let Monatszins = Zins / this.state.ratenJahr;
      return Betrag*Monatszins*Math.pow(1+Monatszins,Laufzeit) / (Math.pow(1+Monatszins,Laufzeit)-1) 
    }
}