import React from 'react';
import { Dropdown, TextField, Checkbox, Label, Link, DatePicker,Position } from '@fluentui/react';
import './Formular.css';
import config from '../config'
import { SpinEdit } from './libery';

type FormularProps = Readonly <{
    elements:any,
    onGetValue?:any,
    onChange:any,
    data:any
}>
interface FormularState {
   
}

export class Formular extends React.Component<FormularProps, FormularState> {
    render(){
        let className = "Formular";
        
        return(
            <div className={className}>
                {this.props.elements.map((element:any, index:any)=>
                    <Element 
                        key={index}
                        element={element}
                        onChange={(element:any, value:any)=>{
                            let WD={...this.props.data||{}}
                            WD[element.savePath] = value;
                            if (typeof(this.props.onChange)==='function'){
                                this.props.onChange(WD, element);
                            }
                        }}
                        onDelete={(element:any)=>{
                            let WD={...this.props.data||{}}
                            delete WD[element.savePath];
                            if (typeof(this.props.onChange)==='function'){
                                this.props.onChange(WD, element);
                            }
                        }}
                        onDeleteMultiple={(elements:any)=>{
                            let WD={...this.props.data||{}}
                            elements.forEach((element:any)=>{
                                delete WD[element.savePath];
                            })
                            if (typeof(this.props.onChange)==='function'){
                                this.props.onChange(WD, elements);
                            }
                        }}
                        onGetValue={(element:any, def:any)=>{
                            if (typeof(this.props.onGetValue)==='function'){
                               return this.props.onGetValue(element, def);
                            } else 
                            if(this.props.data!==null){
                                let WD={...this.props.data}
                                if(typeof(WD[element.savePath])!=='undefined'){
                                    let result = WD[element.savePath] ;
                                    return result;
                                }
                            }
                            return typeof(def)!=='undefined' ? def : false;
                        }}
                    />
                )}
            </div>
        )
    }
}
export default Formular;

type ElementProps = Readonly <{
    element:any,
    onGetValue:any,
    onChange:any,
    onDelete:any,
    onDeleteMultiple:any
}>
interface ElementState {
   
}

export class Element extends React.Component <ElementProps, ElementState>{
    render(){
        const element  = this.props.element;

        if (typeof(element)!=='undefined'){
            //let value="";
           
            switch (element.type.toLowerCase()){
                case "email":
                case "text":
                case "time":
                case "number":
                    if (!this.props.onGetValue(element)){
                        if (typeof(this.props.element.defaultValue)!=='undefined'){
                            this.props.onChange(element,this.props.element.defaultValue);
                        }
                    }
                    delete element.defaultValue;
                    return <TextField 
                                className="columns"
                                suffix={this.props.element.unit !==''?this.props.element.unit:undefined}
                                {...element}
                                value={this.props.onGetValue(element)}
                                onChange={(e,newValue)=>{
                                    this.props.onChange(element,newValue);
                                }}
                            />
                case "float":
                    //value = this.props.onGetValue(element);
                    return <TextField
                            suffix={this.props.element.unit !==''?this.props.element.unit:undefined}
                            {...element}
                            value={this.props.onGetValue(element)}
                            onChange={(e:any,newValue:any)=>{
                                let last = "";
                                if(newValue!==""){
                                    last = newValue.substr(newValue.length-1, 1);
                                    if (last!==',' && last!=='.'){
                                        newValue = parseFloat(newValue.replace(',','.'));
                                    }
                                    } else {
                                        newValue=0;
                                    }
                                    this.props.onChange(element,newValue);
                                }}
                            className="columns"
                        />
                case "file":
                    return <TextField 
                        className="columns"
                        suffix={this.props.element.unit !==''?this.props.element.unit:undefined} 
                        {...element}
                        onChange={(e:any)=>{
                            let file = new FileReader();
                            let filename=e.target.files[0];
                            file.readAsDataURL(e.target.files[0]);
                            file.onload = (e2:any)=>{
                                const data = {file:e2.target.result, filename:filename.name}
                                this.props.onChange(element,data);
                            }
                        }} 
                    />
                case "Date":
                case "date":
                    let value = this.props.onGetValue(element,null);
                    if (value!==null && value!==undefined && value!='') {
                        value = new Date(value)
                    }
                    return (
                        <DatePicker
                            {...config.DatePicker}
                            label={element.label}
                            allowTextInput
                            onSelectDate={(e) => {
                                if (e===null || e===undefined) {
                                    this.props.onChange(element,e)
                                } else {
                                    this.props.onChange(element,e.toISOString());
                                }
                            }}
                            value={value}
                        />)
                case "spinedit" : 
                    return <SpinEdit 
                        label={element.label} 
                        labelPosition={Position.top}
                        min={0}
                        step={0.01}
    
                        precision={2}
                        value={this.props.onGetValue(element,0)}
                        onChangeCustom={(value:number)=>this.props.onChange(element, value)}
                    />       
                case "auswahl":
                    return <Dropdown 
                        {...element}
                        className="columns"
                        label={element.label} 
                        options={element.optionen} 
                        onChange={(e:any,option:any)=>{
                            this.props.onChange(element, option.key);
                        }}
                        selectedKey={this.props.onGetValue(element)}
                    />
                case "integer":
                    return <TextField
                            suffix={this.props.element.unit}
                            {...element}
                            value={this.props.onGetValue(element)}
                            onChange={(e:any,newValue:any)=>{
                                if(newValue!==""){
                                    newValue = parseInt(newValue,10);
                                } else {
                                    newValue=0;
                                }
                                this.props.onChange(element,newValue);
                            }}
                            className="columns"
                          />
                case "auswahlmodelle":
                case "auswahlmultiple":
                    let valueA:any = [];
                    valueA = this.props.onGetValue(element);
                            let O:any = [];
                            element.optionen?.forEach((option:any)=>{
                                if (option.type === 'trenner') {
                                    O.push(<div style={{marginTop:'1em', fontWeight:'bold'}}>{option.text}</div>)
                                } else {
                                    O.push( <Checkbox label={option.text} checked={valueA?.indexOf(option.key)>=0} onChange={(e:any,checked:any)=>{
                                        let index = valueA?.indexOf(option.key);
                                        if (index>=0) {
                                            valueA.splice(index, 1);
                                        } else {
                                            valueA.push(option.key)
                                        }
                                        this.props.onChange(element, valueA)
                                    }} styles={{root:{marginTop: "0.3em"}}} />)
                                }
                            })
                            return(<div className="AuswahlMultiple"><Label>{element.label}
                            | <Link onClick={(e:any)=>this.props.onChange(element, element.optionen.map((o:any)=>o.key))}>Alle auswählen</Link>
                            | <Link onClick={(e:any)=>this.props.onChange(element,[])}>keine auswählen</Link>
                            </Label><div>{O}</div></div>);
                default:
                    return (<div>{JSON.stringify(element)} {element.type}</div>)
            }
        }
    }
}