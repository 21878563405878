import React from 'react';
import { API, ListView, DitgitField, Libery } from '../common';

import { 
  DetailsRow, SelectionMode, Selection, Dropdown, PrimaryButton, DefaultButton, Toggle, Icon, getTheme, mergeStyleSets, Pivot, PivotItem, Link, IDropdownOption,IColumn, IGroup, Text, IFontStyles } from '@fluentui/react';
import './bike.css';
/*
const xml = require("xml-parse");

function gpx2points(gpx){
  return gpx.match(/\<trkpt lat="([\d\.]*)" lon="([\d\.]*)">/g).map(i=>{
    return i.match(/\<trkpt lat="([\d\.]*)" lon="([\d\.]*)">/);
}).map(p => {
  return {lat: parseFloat(p[1]), lng: parseFloat(p[2]) }
})
}

const Karte = (withGoogleMap((props) => {
  let points = gpx2points(props.gpx);
  let center = { 
    lat: points.map(p=>p.lat).reduce((a,b)=>a+b,0)/points.length , 
    lng: points.map(p=>p.lng).reduce((a,b)=>a+b,0)/points.length  
  };
  let bounds = { 
    minLat : Math.min(...points.map(p=>p.lat)),
    maxLat : Math.max(...points.map(p=>p.lat)),
    minLng : Math.min(...points.map(p=>p.lng)),
    maxLng : Math.max(...points.map(p=>p.lng)),
  }
  bounds.width = bounds.maxLng - bounds.minLng;
  bounds.height = bounds.maxLat - bounds.minLat;
  let zoom = 10;
  [0.3, 0.2,  0.1].forEach( grenze =>{
    let M= Math.max(bounds.width, bounds.height);
    if ( M < grenze) {
      zoom++;
    }
  })
   return <GoogleMap
    {...props}
    defaultZoom={zoom}
    defaultCenter={center}
    style={{height:'100%', width:'100%'}}
  >
    <Polyline path={points} options={{strokeColor:'#ff0000'}} />
  </GoogleMap>

}
)) /** */


const theme = getTheme();
//const GROUP_HEADER_AND_FOOTER_SPACING = 8;
const GROUP_HEADER_AND_FOOTER_BORDER_WIDTH = 1;

interface BikeColumn extends IColumn {
  type?:string,
  unit?:string,
  ditgits?:number
}
const BikeConfig = {
  apiBase:  '/bike',
}
interface IBikeGroup extends IGroup {
  min?:number,
  max?:number
}

class Bike extends React.Component<{
  mode:string
},{
  items:any[],
  records:any[],
  diffitems:any[],
  activties:any[],
  last:number,
  selectedKey:string,
  recordKey:string,
  bike:number,
  bikes:any[],
  gpx:any,
  compareMonth:number,
  showZwischensumme:boolean,
  showMonthDS:boolean,
  showHeader:boolean,
  daylilistColumns?:any[]
}> {
  _selection:Selection;
  constructor(props:any){
    super(props);
    this._selection = new Selection();
    this.state = {
      items: [],
      records: [],
      diffitems: [],
      activties:[],
      last: 0,
      selectedKey:'input',
      recordKey:'week',
      bike:1,
      bikes:[],
      showZwischensumme:false,
      compareMonth:(new Date()).getMonth(),
      gpx:null,
      showMonthDS:true,
      showHeader:true
    }
  }
  static getDerivedStateFromProps(props:any, state:any){
    let bike = 1;
    if (typeof(localStorage.bike)!=='undefined'){
      bike=parseInt(localStorage.bike,10);
    }
    state.bike=(typeof(props.match)!=='undefined' && typeof(props.match.params)!=='undefined' && typeof(props.match.params.id)!=='undefined'?parseInt(props.match.params.id,10):bike);
    return state;
  }
 
  componentDidMount(){
    let M=(window.location.href.match('/bike/([0-9]*)'))
    if (M!==null && M.length > 1) {
      this.setState({
        bike:parseInt(M[1])
      }, ()=>this._getItems())
    } else if (typeof(localStorage.bike) !== "undefined") {
      this.setState({
        bike:parseInt(localStorage.bike)
      }, ()=>this._getItems())
    } else {
      this._getItems();
    }
  } 
 
  render() {
    if (this.props.mode === 'dashboard') {
      let yearSum = this.state.items
                  .filter((item:any)=>(new Date(item.time*1000)).getFullYear()===(new Date()).getFullYear())
                  .reduce((sum:number,item:any)=>sum+item?.differenz,0)
                  ;
      let prevYearSum = this.state.items
                  .filter((item:any)=>(new Date(item.time*1000)).getFullYear()===(new Date()).getFullYear()-1)
                  .reduce((sum:number,item:any)=>sum+item?.differenz,0)
                  ;
      
      return <>
        <h2><Text variant='xxLarge'>Fahrrad</Text></h2>
        {this.bikeDD()}
        <Text variant='large' style={{display:'block'}} >{(new Intl.NumberFormat('de-DE', { minimumFractionDigits:2})).format(yearSum)} km <Icon style={{color:theme.palette.neutralTertiary, fontSize:'0.8em'}} iconName={prevYearSum > 0 ? (prevYearSum === yearSum ? 'Forward' : (prevYearSum < yearSum ? 'Up' : 'Down'))  : 'Forward'} /></Text>
        <Text variant='medium' style={{display:'block'}}>{(new Intl.NumberFormat('de-DE', { minimumFractionDigits:2})).format(prevYearSum)} km </Text>
      </>
    }
    return this.normal()
  }


  bikeDD(){
    return <Dropdown
        label=""
        selectedKey={this.state.bike}
        onChange={(ev,item:IDropdownOption<any> | undefined)=>{
          this.setState({bike:parseInt(String(item?.key),10) ||0 },()=>{
            localStorage.setItem('bike',String(item?.key))
            this._getItems(parseInt(String(item?.key),10));
            if (this.props.mode !== 'dashboard')
            window.location.href=('/bike/'+item?.key)
          });
        }}
        placeholder="Select an option"
        options={this.state.bikes}
        styles={{ dropdown: { maxWidth: 450, marginBottom:'1em' } }}
      />
  }
  normal() {
    return (
      <div className="Bike component">
        <div className="main-view"        >
        {this.bikeDD()}
        <Toggle label="" checked={this.state.showHeader} 
            onText="Steuerelemente" offText="Steuerelemente" onChange={(e, checked) => this.setState({showHeader:checked===true})} />

        {this.state.showHeader&& <header>
        <div style={{display:'flex', maxWidth: '450px', justifyContent:'space-between', margin:'1em 0'}}>
        <DitgitField value={this.state.last} 
                    percison={2}
                    onChange={this.handleChangeDistanz.bind(this)}/>
              <div style={{marginLeft:'1em', display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
               <PrimaryButton onClick={this.handleBuchen.bind(this)}>Buchen</PrimaryButton>
               <DefaultButton onClick={this.handleDeleteLast.bind(this)}>letzte löschen</DefaultButton>
               <DefaultButton onClick={(e)=>{
                 window.location.href='https://www.strava.com/oauth/authorize?client_id=66737&response_type=code&redirect_uri=http://tools.huebschi.de/api/bike/strava/setCode?return='+encodeURI(window.location.href)+'&approval_prompt=force&scope=read_all,activity:read_all';
              }}>Strava Auth</DefaultButton>
              </div>
          </div>
       </header> }
       <div style={{position:'relative', flexGrow:1}}>
        <Pivot selectedKey={this.state.selectedKey} onLinkClick={(item)=>{
              this.setState({selectedKey:item?.props.itemKey||''});
            }} linkFormat={"links"} className={"tabs"}>
          <PivotItem headerText="Aufzeichnung" itemKey="input">
          </PivotItem>
          <PivotItem headerText="Rekorde" itemKey="records"></PivotItem>
          <PivotItem headerText="Überblick" itemKey="overview"></PivotItem>
          <PivotItem headerText="Monatsvergleich" itemKey="MonthCompare"></PivotItem>
          <PivotItem headerText="Activitäten" itemKey="Activities"></PivotItem>
        </Pivot>
        {this.state.selectedKey==="input" && this.daylilist()}
        {this.state.selectedKey==="records" && this.recordlist()}
        {this.state.selectedKey==="overview" && this.difflist()}
        {this.state.selectedKey==="MonthCompare" && this.MonthCompare()}
        {this.state.selectedKey==="Activities" && this.Activities()}
        </div>
      </div>
      </div>
    );
    
  }
  MonthCompare() {
    
  let items=[...this.state.items];
  let MonthMatrix:any={};
    for (let i =items.length-1; i>=0; i--){
      let J=Libery.formatDate(items[i].time,'JJJJ')
      let M=Libery.formatDate(items[i].time,'MM');
      let T=Libery.formatDate(items[i].time,'DD');
      if (typeof MonthMatrix[J] === 'undefined') {
        MonthMatrix[J] = {}
      }
      if (typeof MonthMatrix[J][M] === 'undefined') {
        MonthMatrix[J][M] = {}
      }
      MonthMatrix[J][M][T] = items[i].monatssumme;
    }
    let namen = [];
    for( let i=0; i<12; i++){
      const event = new Date(Date.UTC(2012, i, 20, 3, 0, 0));
      namen.push(event.toLocaleDateString('de-DE',{month:'long'}))
    }
    
    let data:any[] = [];

    let tage = (new Date(2020, this.state.compareMonth+1, 0)).getDate();
  
    let Monat:any = this.state.compareMonth+1;
    Monat = String((Monat<10?'0':'')+Monat);
    for (let i=1; i<=tage; i++){
        let satz:any={tag:i};
        let tag = String((i<10?'0':'')+i);
        Object.keys(MonthMatrix).forEach(element => {
          satz[element] = null;
          if (typeof(MonthMatrix[element])!=='undefined') {
            if (typeof(MonthMatrix[element][Monat])!=='undefined') {
              if (typeof(MonthMatrix[element][Monat][tag])!=='undefined') {
                satz[element] = MonthMatrix[element][Monat][tag];
              }
            }
          }
          if (satz[element]===null && data.length > 0){
            satz[element] = data[data.length -1][element]
          }
        });
        data.push(satz);
    }
    let columns:BikeColumn[]=[{
      name:'Tag',
      fieldName:'tag',
      maxWidth:10,
      minWidth:10,
      key:'tag'
    }];
    Object.keys(MonthMatrix).forEach((element,i) => {
      columns.push({
        name:element,
        fieldName:element,
        type:'number',
        unit:'km',
        ditgits:2,
        onRender:(item?:any, index?:number, column?:IColumn)=>this.onRenderItemColumn(item, index||0, column),
        maxWidth:55,
        minWidth:50,
        key:'C'+i
      });
      if (this.state.showMonthDS){
        columns.push({
          name:'Ø',
          fieldName:element,
          type:'number',
          unit:'km',
          ditgits:2,
          key:'ds',
          onRender:(item, index, column)=>{
            let sum = 0;
            let count = 0;
            Object.keys(MonthMatrix).forEach(element2=> {
              if (element2<=element && typeof(item[element2])==="number") {
                sum+=item[element2];
                count++;
              }
            })
            return (<span style={{
                        display: 'block',
                        textAlign: 'right',
                    }}>{Libery.formatNumber(sum/count,2,',','.')+'km'}</span>)
          },
          maxWidth:55,
          minWidth:50
        });
      }
    });
    return <>
    <Dropdown 
      options={namen.map((o,i)=>{
        return {key:i, text:o}
      })}
      selectedKey={this.state.compareMonth}
      onChange={(e,option)=>this.setState({compareMonth:parseFloat(String(option?.key))})}

    />   
    <Toggle label="" checked={this.state.showMonthDS} 
            onText="Durchschnitt" offText="Durchschnitt" onChange={(e, checked) => this.setState({showMonthDS:checked === true})} />

    <ListView 
      columns={columns}
      
      items={data}
      selection={this._selection}
      
    />
    </>
  }
  Activities() {
    let columns=[];
    columns.push({
      name:'Beginn',
      onRender:(item:any)=>{
        let d=new Date(item.start_date);
        return d.toLocaleDateString('de-DE',{ year: 'numeric', month: '2-digit', day: '2-digit' })
            + "\n"+ d.toLocaleTimeString('de-DE')
      },
      maxWidth:55,
      minWidth:50,
      isMultiline:true
    });
    columns.push({
      name:'Ende',
      onRender:(item:any)=>{
        let d=new Date(item.start_date);
        d.setSeconds(d.getSeconds() + item.elapsed_time);
        return d.toLocaleDateString('de-DE',{ year: 'numeric', month: '2-digit', day: '2-digit' })
            + "\n"+ d.toLocaleTimeString('de-DE')
      },
      maxWidth:55,
      minWidth:50,
      isMultiline:true
    });
  
    columns.push({
      name:'Strecke',
      fieldName:'distance',
      type:'number',
      unit:'km',
      digits:4,

      onRender:(item:any, index:number, column:IColumn)=>{
        let copy={...item};
        copy[column.fieldName||'']/=1000;
        return <div style={{textAlign:'right'}}><Link onClick={()=>{
          this.setState({
            last:this.state.last + Math.round(copy.distance*100)/100
          })
        }}>{this.onRenderItemColumnRecords(copy, index, column)}</Link></div>
      },
      maxWidth:70,
      minWidth:150,
    //  isMultiline:true
    });
    columns.push({
      name:'Gesamt',
      fieldName:'gesamt',
      type:'number',
      unit:'km',
      digits:4,

      maxWidth:70,
      minWidth:80,
    //  isMultiline:true
    });
    /*
    columns.push({
      name:'GPX',

      onRender:(item, index, column)=>{
        return <Link onClick={()=>{
          
          fetch(API.base+BikeConfig.apiBase+'/strava/activities/'+item.id+'/gpx' , {
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': API.getAuth()
                }, 
                method: 'GET',
                mode: 'cors'
            }).then(response => response.text()).then(result=>{
              let M = (result.match('<name>(.*)</name>'));

              this.setState({gpx:result, gpxTitle : M.length > 1 ? M[1] : ''})
            });
        }}>GPX</Link>
      },
      maxWidth:55,
      minWidth:50,
      isMultiline:true
    }); /** */
    columns.push({
      name:'Name',
      onRender:(item:any)=>{
        return item.name
      },
      //maxWidth:55,
      minWidth:150,
      isMultiline:true
    });
    return <>
    <ListView 
      columns={columns}
      layoutMode={1}
      
      items={this.state.activties}
      selection={this._selection}
      onRenderItemColumn={this.onRenderItemColumn}
      key="list"
    />{/*
    <Modal isOpen={this.state.gpx !== null} onDismiss={()=>this.setState({gpx:null}) }
        containerClassName={contentStyles.container} 
    
    >
    <div className={contentStyles.header}>
      <span >{this.state.gpxTitle}</span>
      <IconButton
        iconProps={{iconName:'Cancel'}}
        ariaLabel="Close popup modal"
        onClick={()=>this.setState({gpx:null})}
      />
    </div>
    {this.state.gpx !== null ?
    <div className={contentStyles.body}>
          
          <Karte isMarkerShown 
                googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key="+config.maps.apikey}
                loadingElement={<div style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '100%' }} />}
                
                gpx={this.state.gpx}
            />
      </div>:''}
    </Modal> */}
    </>
  }
  daylilist() {
    
    const classNames = mergeStyleSets({
      headerAndFooter: {
        borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        padding: '2px 1em',
        margin: `0`,
        background: theme.palette.neutralLighterAlt,
        // Overlay the sizer bars
        position: 'relative',
        display:'flex',
        alignItems:'center'
      },
      headerTitle: [
        theme.fonts.xLarge,
        {
          padding: '4px 0'
        }
      ],
      headerLinkSet: {
        margin: '4px -8px'
      },
      headerLink: {
        margin: '0 8px'
      }
    });
  let groups:IBikeGroup[]=[];
  let last = '';
  let items=[];
  for (let j =this.state.items.length-1; j>=0; j--){
    items.push(this.state.items[j]);
  }
  for (let i=0; i<items.length; i++){
    let key=Libery.formatDate(items[i].time,'JJJJ/MM');
      if (key !== last) {
        let heute = Libery.formatDate(Math.round((new Date()).getTime()/1000),'JJJJ/MM');
        groups.push({ 
          key: key,
          name: key,
          count: 1,
          isCollapsed:key!==heute,
          min : groups.length>0? groups[groups.length-1].max : 0,
          max : items[i].distanz,
          startIndex:groups.length
         })
      } else {
        groups[groups.length-1].count++;
        groups[groups.length-1].min=i+1<items.length ? items[i+1].distanz:0;
      }
      last=key;
  }
 // groups=groups.reverse();
 if (groups.length > 1)
    groups[0].min = groups[1].max
  let groups2:IBikeGroup[]= [];
  let startIndex=0;
  
  if (groups.length > 0){
    for (let i=0; i<groups.length; i++){
      let key=(groups[i].key.split('/')[0]);

      if (groups2.length > 0) {
        groups[i].startIndex=startIndex;
        if (key === groups2[groups2.length-1].key){
          groups2[groups2.length-1].count+=groups[i].count;
          groups2[groups2.length-1].min=groups[i].min;
          if((groups2[groups2.length-1].max||0)<(groups[i].max||0))
              groups2[groups2.length-1].max=groups[i].max;
          
          groups2[groups2.length-1].children?.push(groups[i]);
        } else {
          groups2.push({
              key: key,
              name: key,
              startIndex:  startIndex, 
              count: groups[i].count, 
              max: groups[i].max,
              isCollapsed:key!==Libery.formatDate(Math.round((new Date()).getTime()/1000),'JJJJ'),
              children:[groups[i]]
          })
        }
      } else {
        groups2.push({
            key: key,
            name: key,
            startIndex: startIndex, 
            count: groups[i].count,
            isCollapsed:key!==Libery.formatDate(Math.round((new Date()).getTime()/1000),'JJJJ')+'',
            children:[groups[i]],
            max: groups[i].max
        })
      }
      startIndex+=groups[i].count;
    }
  }
  
  last='';

  
    return (
       <ListView 
      columns={this.state.daylilistColumns}
      groups={groups2}
          
      groupProps={{
        onRenderHeader:  (props:any) => {
          return (
            <div className={classNames.headerAndFooter} onClick={
              ()=> {
                return  props!.onToggleCollapse!(props.group);
              }
            }>
            
            <Icon iconName={props.group?.isCollapsed?'ChevronRightMed':'ChevronDownMed'} className="ms-IconExample" />
            
              <div style={{width:'5em',paddingLeft:'0.8em'}}className={classNames.headerTitle}>{props.group?.name}</div>
              {true &&
              <div >
              <div >
                <span style={{ display:'inline-block',marginRight:"1em"}}>{Libery.formatNumber(props.group?.min,2,',','.')} km - <b>{Libery.formatNumber(props.group?.max,2,',','.')} km</b></span>
                
              <span style={{ display:'inline-block'}}>
                      {Libery.formatNumber(props.group.max-props.group.min,2,',','.')} km in&nbsp;{props.group.count}&nbsp;Tage{props.group.count!==1?'n':''}
                      <span style={{display: 'inline-block'}}>({Libery.formatNumber( Math.round((props.group.max-props.group.min)/props.group.count*100)/100,2,',','.')} km/Tag)</span></span>
              </div>
              <div style={{width:'4em'}}></div>
              </div>}
            </div>
          );
        }
      }}
      items={items}
      selection={this._selection}
      footer={<div></div>}
      selectionMode={0}
      
      onRenderItemColumn={this.onRenderItemColumn}
    />
     )
  }
  onRenderItemColumn(item:any, index:number, column?:BikeColumn){
    if (index){}
    let columnType =typeof(column?.type)!=='undefined' ? (column?.type) : '';
    let columnUnit =typeof(column?.unit)!=='undefined' ? ' '+(column?.unit) : '';
    
    let inhalt='';
    switch (columnType){
      case 'number':
        inhalt = Libery.formatNumber(item[column?.fieldName||''],column?.ditgits||2,',','.')+columnUnit;
      break;
      case 'date':
        inhalt = Libery.formatDatum(item[column?.fieldName||'']);
      break;
      default: 
        inhalt = item[column?.fieldName||'']+'';
      break;
      
    }
    return (<span style={{
                display: 'block',
                textAlign: 'right',
            }}>{inhalt}</span>)
  }
  onRenderItemColumnRecords(item:any, index?:number, column?:BikeColumn){
    return this.onRenderItemColumn(item,index||0,column)
  }
  recordlist() {
  let items=[];
    for (let  j=0;j <=this.state.records.length-1; j++){
      items.push(this.state.records[j]);
    }
    
    return (
      <div style={{flexGrow:"1", display: "flex", flexDirection:'column'}}>
         <Dropdown
        label=""
        selectedKey={this.state.recordKey}
        onChange={(ev,item)=>{
          this.setState({recordKey:item?String(item?.key)||'':''},()=>this._getItems());
        }}
        placeholder="Select an option"
        options={[
          { key: 'day', text: 'nach Tag' },
          { key: 'week', text: 'nach Woche' },
          { key: 'month', text: 'nach Monat' },
          { key: 'year', text: 'nach Jahr' },
        ]}
        styles={{ dropdown: { width: 300 } }}
      />
      
      <ListView 
      columns={
        [
              
          {
            key: 'column1',
            name: 'Schlüssel',
            fieldName:'Format',
            isResizable: true,
            isPadded: false,
            minWidth:55,
            maxWidth: 55,
            onRender:(item:any, index:number, column:BikeColumn)=>this.onRenderItemColumnRecords(item,index||0,column)
          } ,
          {
            key: 'column2',
            name: ('km'),
            fieldName:'distanz',
            isResizable: true,
            isPadded: false,
            className:'umbruch',
            minWidth:45,
            maxWidth: 50,
            type:'number',
            digits:2,
            unit:'km',
            onRender:(item:any, index:number, column:BikeColumn)=>this.onRenderItemColumnRecords(item,index||0,column)
          } 
        ]
      }
      
      items={items}
      selection={this._selection}
      footer={<div></div>}
      
    />
      </div> )
  }
  difflist() {
  let items:any[]=[],jahre:number[]=[],monate:string[]=[];
  const MoTxt=['','Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'];
  
    for (let  j=0;j <=this.state.diffitems.length-1; j++){
      if (jahre.indexOf(this.state.diffitems[j].Jahr) < 0){
        jahre.push(this.state.diffitems[j].Jahr);
      }
      let Monat:string = (this.state.diffitems[j].Monat<10?'0':'')+this.state.diffitems[j].Monat;
      if (monate.indexOf(Monat) < 0){
        monate.push(Monat);
      }
    }
    
    let columns:BikeColumn[]=[
              
      {
        key: 'column1',
        name: 'Monat',
        fieldName:'title',
        isResizable: true,
        isPadded: false,
        minWidth:70,
        maxWidth: 70,
        onRender:(item, index, column)=>this.onRenderItemColumnRecords(item,index||0,column)
      }
    ];
    jahre.forEach(element => {
      columns.push(  {
        key: 'column'+element,
        name: (element+''),
        fieldName:element+'',
        isResizable: true,
        isPadded: false,
        minWidth:60,
        maxWidth: 70,
        type:'number',
        ditgits:2,
        unit:'km',
        onRender:(item, index, column)=>this.onRenderItemColumnRecords(item, index, column)
      } );
      
      columns.push({
        name:'Ø',
        fieldName:String(element),
        key:String(element)+'Ø',
        type:'number',
        unit:'km',
        ditgits:2,
        onRender:(item, index, column)=>{
          let sum = 0;
          let count = 0;
          jahre.forEach(element2=> {
            if (element2<=element && typeof(item[element2])==="number") {
              sum+=item[element2];
              count++;
            }
          })
          return (<span style={{
                      display: 'block',
                      textAlign: 'right',
                  }}>{Libery.formatNumber(sum/count,2,',','.')+'km'}</span>)
        },
        maxWidth:55,
        minWidth:50
      });
    });
    
    
    let Footeritem:any = {
      title:'Summe'
    };
    
    jahre.forEach(jahr => {Footeritem[jahr]=0});
    jahre.sort();
    monate.sort();
    monate.forEach(monat => {
      let item:any = {
        title:MoTxt[parseInt(monat,10)]
      };
      jahre.forEach(jahr => {
        let wert = 0;
        
        for (let  j=0;j <=this.state.diffitems.length-1; j++){
          if ((this.state.diffitems[j].Jahr===jahr) && (this.state.diffitems[j].Monat === parseInt(monat,10))){
            wert = this.state.diffitems[j].diff;
            Footeritem[jahr] += this.state.diffitems[j].diff;
          }
        }
        item[jahr] = wert;
      });
      items.push(item);
      if (this.state.showZwischensumme && parseInt(monat)<12) {
        item={title:'Zwischesumme'};
          
        jahre.forEach(jahr => {
          let wert = 0;
          
          for (let  j=0;j <=this.state.diffitems.length-1; j++){
            if ((this.state.diffitems[j].Jahr===jahr) && (this.state.diffitems[j].Monat <= parseInt(monat,10))){
              wert  += this.state.diffitems[j].diff;
            }
          }
          item[jahr] = wert;
        });
        items.push(item)

      }
    });
    return (
      <div style={{flexGrow:"1", display: "flex", flexDirection:'column'}}>
        <Toggle label="" checked={this.state.showZwischensumme} onText="Zwichensumme" offText="Zwichensumme" onChange={(e, checked) => this.setState({showZwischensumme:checked===true})} />

      <ListView 
      columns={columns}
      
      items={items}
      selection={this._selection}
      footer={<div></div>}
      
      onRenderDetailsFooter={(detailsFooterProps:any) => {
        return (
          <DetailsRow
            {...detailsFooterProps}
            columns={detailsFooterProps.columns}
            item={Footeritem}
            itemIndex={-1}
            selectionMode={SelectionMode.none}
            selection={detailsFooterProps.selection}
          />
        );
      }}
    />
      </div> )
  }
  
  handleChangeDistanz(pvalue:any){
    this.setState({
      last : pvalue
    });
  }

  handleBuchen(){
    API.post(BikeConfig.apiBase+'/'+this.state.bike, {distanz:this.state.last})
      .then( (result) => {
        this._getItems();
      });
  }

  handleDeleteLast(){
    if (window.confirm('Letzte Buchung löschen')){
      API.delete(BikeConfig.apiBase+'/'+this.state.bike )
        .then( () => {
          this._getItems();
        });
    }
  }
  //API-Aufruf, um die die Kunden abzurufen
  _getItems(bike?:number){ 
    
    var last;
    if (typeof(bike)==='undefined'){
      bike = this.state.bike
    }

    API.get(BikeConfig.apiBase+'/list' )
      .then( (result) => {
        this.setState({
          bikes: result
        }); 
    }).catch( (error) => {
      console.error(error);
    });
    API.get(BikeConfig.apiBase+'/columns' )
      .then( (result) => {
        this.setState({
          daylilistColumns: result
        }); 
    }).catch( (error) => {
      console.error(error);
    });
    API.get(BikeConfig.apiBase+'/'+bike )
      .then( (result) => {
        result = result.reverse();
            
        if (result.length > 0 ) {
          last = result[0].distanz;
        } else {
          last = 0;
        }
        result = result.reverse();
        this.setState({
          items: result,
          last : last
        }); 
    }).catch( (error) => {
      console.error(error);
    });
    API.get(BikeConfig.apiBase+'/'+this.state.bike+'/records/'+this.state.recordKey )
      .then( (result) => {
        this.setState({
          records: result
        }); 
    }).catch( (error) => {
      console.error(error);
    });
    API.get(BikeConfig.apiBase+'/'+this.state.bike+'/difflist' )
      .then( (result) => {
        this.setState({
          diffitems: result
        }); 
    }).catch( (error) => {
      console.error(error);
    });
    API.get(BikeConfig.apiBase+'/strava/activities' )
      .then( (result) => {
        result = result.reverse();
        result = result.map((item:any,index:number)=>{
          if(index > 0) {
            item.gesamt = (result[index-1].gesamt||0) + item.distance/1000;
          } else {
            item.gesamt = item.distance/1000;
          }
          item.gesamt = Math.round(item.gesamt*10000)/10000;
          return item;
        })
        this.setState({
          activties: result.reverse()
        }); 
        /** */
    }).catch( (error) => {
      console.error(error);
    });
  }

   
}

export default Bike;